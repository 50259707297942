import React from "react";
import { Helmet } from "react-helmet";
import { kebabCase } from "lodash";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import BlogHeader from "../components/BlogHeader";
import TagList from "../components/TagList";

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges;
    const postLinks = posts.map((post) => (
      <article className='blog-list-line-item columns' key={post.node.id}>
        <div className='column is-4'>
          <Link to={post.node.fields.slug}>
            {post.node.frontmatter.featuredimage ? (
              <div className='featured-thumbnail'>
                <PreviewCompatibleImage
                  imageInfo={{
                    image: post.node.frontmatter.featuredimage,
                    alt: `featured image thumbnail for post ${post.node.frontmatter.title}`,
                  }}
                />
              </div>
            ) : null}
          </Link>
        </div>
        <div className='column'>
          <div className='is-flex is-flex-direction-column h-100 is-justify-content-space-between'>
            <Link to={post.node.fields.slug}>
              <p className='subtitle'>{post.node.frontmatter.date}</p>
              <p className='post-meta title is-size-4 mt-2'>{post.node.frontmatter.title}</p>
            </Link>
            {post.node.frontmatter.tags && post.node.frontmatter.tags.length ? (
              <p className='has-text-right mt-2'>
                {post.node.frontmatter.tags.map((tag) => (
                  <Link className='button is-small' key={tag + `tag`} to={`/tags/${kebabCase(tag)}/`}>
                    {tag}
                  </Link>
                ))}
              </p>
            ) : null}
          </div>
        </div>
      </article>
    ));
    const tag = this.props.pageContext.tag;
    const title = this.props.data.site.siteMetadata.title;
    const siteUrl = this.props.data.site.siteMetadata.siteUrl;
    const totalCount = this.props.data.allMarkdownRemark.totalCount;
    const tagHeader = `“${tag}”の記事一覧 全${totalCount}件${totalCount === 1 ? "" : ""} `;

    return (
      <Layout>
        <BlogHeader />
        <section className='section pt-4'>
          <Helmet
            title={`“${tag}”の記事一覧 - CONNECT BLOG | ${title}`}
            meta={[
              { property: "og:title", content: `“${tag}”の記事一覧 - CONNECT BLOG | ${title}` },
              { property: "og:url", content: encodeURI(`${siteUrl}/tags/${tag}/`) },
            ]}
          />
          <div className='container content'>
            <div className='columns'>
              <div className='column is-9' style={{ marginBottom: "6rem" }}>
                <h1 className='heading is-size-4'>{tagHeader}</h1>
                <div className='mt-3 blog-list'>{postLinks}</div>
              </div>
              <aside className='column'>
                <h2 className='orbitron heading is-size-4'>Tags</h2>
                <TagList />
              </aside>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark(limit: 1000, sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { tags: { in: [$tag] } } }) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            tags
            templateKey
            date(formatString: "YYYY/MM/DD")
            featuredimage {
              childImageSharp {
                gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;
